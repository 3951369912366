import * as moment from "moment";

export const today = moment().utc().locale("pt-br");

export function dateIsToday(date) {
  if (!date) return false;

  let dateToCheck = moment.utc(date).format("YYYY-MM-DD");
  return dateToCheck == today.format("YYYY-MM-DD");
}
