const general = {
  total: "Total",
  recommended: "Recommended",
  attention: "Attention",
  settings: "Settings",
  customize: "Customize",
  check_payment: `To check your payments, access your <strong><a class="font-weight-bold" href="https://brlgestao.superlogica.net/clients/areadocliente/clientes/" target="_blank">Customer Area</a></strong> on Superlogica.`,
  check_payment_mistake:
    "Si ya has realizado el pago y crees que puede haber ocurrido algún error, por favor contacta a financeiro@conectasuite.com.",
  language: "Language",
  theme: "Theme",
  google_admin: "Admin Google",
  coming_soon: "Coming Soon",
  try_new_sso: "Try the new Control",
  schedule_meeting_button: "Talk with the team",
  successful_activation_title: "Alright! Access control enabled",
  successful_activation_description_title_1:
    "From now to access Workspace, users must follow the rules configured in their respective shifts",
  successful_activation_description_title_2:
    "If they have not configured a password, the next login users must use the temporary password to access",
  successful_activation_description_title_3:
    "Non-shift users will not be able to access Workspace",
  successful_activation_description_subtitle_3: `Did you forget someone? Go to <span class="font-weight-bold"><a href="/users">Users</a></span> to fix the issue.`,
  schedule_explanation:
    "Our team is available for you to answer all your questions. Schedule an appointment and talk to our team of specialists.",
  control_activation_schedule_title:
    "Did you have any doubts about activating Control?",
  illustration_by: "Illustration by",
  illustrations_by: "Illustrations by",
  seconds: "Seconds",
  second: "Second",
  tutorial_video: "Video tutorials",
  and: "and",
  uppercase_send_email: "Send e-mail",
  lowercase_send_email: "send e-mail",
  successful_schedule_activation_title:
    "Access control activation scheduled for",
  successful_schedule_activation_description_title_1:
    "We will activate the control automatically on the date and time informed above",
  successful_schedule_activation_description_subtitle_1:
    "You can cancel your appointment at any time",
  successful_schedule_activation_description_title_2:
    "After activation, to access Workspace, users must follow the rules configured in their respective shifts",
  successful_schedule_activation_description_title_3:
    "If users have not configured a password, the next login after activation, users must use the temporary password to access",
  successful_schedule_activation_description_title_4:
    "Off-shift users will not be able to access Workspace after the activation date",
  suspended_alert:
    "Dear customer, <br /> Your access is temporarily suspended due to a delay in processing payment for your plan.",
  service_soon_available: "Service will be available within a few days!",
  business_contact:
    "Our commercial team has been notified and will be in touch shortly.",

  // TODO: REMOVER AVISOS DO SSO ANTIGO APÓS 04/03/24
  old_sso_title_message:
    "Important Notification: There is a new SSO version available!",
  old_sso_title_message_2:
    "The current version of the single sign-on system will be deactivated on 04/03/2024, and the latest version will take effect.",
  old_sso_sub_message:
    "Get ahead and configure the Conecta Control update now to enjoy a more efficient and up-to-date login system. Consult the documentation and follow the step-by-step guide for a smooth transition.",
  permissions: "Permissions",

  temporary_access_alert_rules: "<b>Temporary Access</b> Rules:",
  temporary_access_description:
    "Temporary access overrides all shift rules, allowing the user to access their email account.",
  temporary_access_description2:
    "By setting an access time, the user can log in at any time within the valid period.",
  temporary_access_description3:
    "If no time is set, the user will lose temporary access as soon as they log off.",
  notification: "Notification",
  grant_single_access_description:
    "Granting temporary access without setting a time will revoke it on the first logoff. If a time is set, the user can log in at any time within the valid period. </br> The access time starts counting from the next login.",
  remove_temporary_access_description:
    "You are about to remove temporary access for this user. They will not be able to log in if they are outside of the shift rules.",
  remove_temporary_access:
    "Removing temporary access will log this user out of all accounts!",
  recomendation_on_remove_temporary_access:
    "Are you sure you want to remove temporary access for this user?",
  force_user_logout:
    "Do you want to force this user to log out when removing temporary access?",
};

const feedback = {
  rating_error: "Select a satisfaction level to send the survey.",
  no_evaluation: "No evaluation",
  very_unsatisfied: "Very unsatisfied",
  unsatisfied: "Unsatisfied",
  indifferent: "Indifferent",
  satisfied: "Satisfied",
  very_satisfied: "Very satisfied",
  evaluate_your_experience: "Rate your experience in",
  review_sent: "Review sent, thank you!",
  leave_a_comment: "Leave a comment (Optional)",
  successful_feedback_sent: "All right, evaluation registered!",
  edit_feedback: "Edit my feedback",
  feedback_error: "Error registering feedback, please try again",
  rate_your_experience: "Rate your experience",
  rate_your_experience_subtitle:
    "Share with us your experience using this feature.",
  rate_app_on_marketplace: "Rate the app on the marketplace",
  common_did_not_work: "Did not work",
  common_confusing_interface: "Confusing interface",
  common_slow_performance: "Slow performance",
  common_options_missing: "Options missing",
  group_add_or_remove_users_incorrect: "Incorrect user/group",
  group_add_or_remove_users_missing: "User/group not found",
  change_user_password_incorrect: "Incorrect password",
  change_user_password_missing: "Rules missing",
  change_user_password_usability: "Excessive rules",
  customize_login_preview: "Preview was different from login",
  control_activation_usability: "Users not blocked",
  control_activation_frequent: "Unexpected activation",
  message_placeholder: "Because...",
};

/* TELA DE LOGIN */
const login = {
  login: "Sign in with Google",
  msLogin: "Sign in with Microsoft",
  help: "Help",
  login_description:
    "This app is only available to Google Workspace corporate admins, so you must sign in with your admin account!",
  privacy: "Privacy",
  terms: "Terms",
  init_session: "Sign in to continue",
  login_preview_info: "This is a preview of your login page",
  agreement_label: "By creating an account, you agree to our ",
  slogan: `<span class="login-text-spotlight">Control</span> <br /> the <span class="login-text-spotlight">use</span> <br /> of <span class="login-text-spotlight">corporate email</span>`,
  scopes_help: "Understand the requested scopes",
  try_for_free: "Try it for free",
  feature_list_label_1: "Access restriction by time, location and device",
  feature_list_label_2: "Logout of users after shift hours",
  feature_list_label_3: "and much more!",
  installApp: "Install the App on your Google Workspace",
  retryWaitingGoogle: "Connecting to Google. Please wait a moment.",
  retryWaitingMicrosoft: "Connecting to Microsoft. Please wait a moment.",
  retryJustInstalled:
    "If you just installed the application, this may take a few minutes.",
  retryEnsureScopes:
    "Please ensure you have granted the necessary permissions.",
};

/* TELA DE AUTENTICAÇÃO */
const auth = {
  authenticating: "Authenticating",
  error_invalid_grant:
    "Invalid URL. Please check your permissions and try again.",
  error_missing_code:
    "An error occurred getting the token. Please check your permissions and try again.",
  error_no_domain_associated:
    "There is no domain associated with this account. Please sign in with an account associated with Workspace.",
  error_no_tenant_associated:
    "There is no tenant associated with this account. Please sign in with an account associated with Microsoft 365.",
  error_unregistered_user: "User not found in our database.",
  error_marketplace_not_installed: `App is not installed on your domain. Please install the app from the <a href="https://workspace.google.com/marketplace/app/control/${process.env.VUE_APP_MARKETPLACE_APP_ID}" target="_blank">Google Workspace Marketplace</a>.`,
  error_azure_not_installed: `App is not installed on your tenant. Please install the app by trying to log in and accepting the scopes.`,
  error_invalid_google_access_token: `Invalid access token. Some access permission is pending. To login you need to allow access to all scopes in the <a href='https://admin.google.com/ac/apps/gmail/marketplace/appdetails/${process.env.VUE_APP_MARKETPLACE_APP_ID}' target='_blank'>Google Admin Console</a>.`,
  error_invalid_microsoft_access_token: `Invalid access token. Some access permission is pending or is still being processed by the provider.`,
  error_not_google_admin:
    "You're not a Google Workspace Administrator or don't have permission to access Conecta Control.",
  error_invalid_callback: "Invalid URL. Please try again.",
  error_forbidden_customer:
    "It is not possible to simulate the login of this client. Check Partner permissions and try again.",
  error_invalid_token: "Invalid token, please login and try again.",
  error_login_required: "Please login and try again.",
  error_missing_scope:
    "Important features may fail to work properly due to pending scopes in the <strong>Conecta Control</strong> application.",
  error_missing_scope_alert:
    "The <strong>URGENT</strong> resolution of these scopes is essential for the FULL FUNCTIONALITY of the system.",
  error_missing_scope_support:
    "If you have any questions, please contact our support team through the available channels.",
  error_missing_scope_for_non_admin:
    "Contact your Google Workspace administrator to request authorization for the necessary scopes.",
  error_unknown: "An unknown error occurred. Please, try again.",
  error_company_banned:
    "Suspended access due to technical criteria or violation of the terms of use.",
  marketplace_install:
    "Conecta Control is not installed in your Google Workspace, install now to continue.",
};

/* FORMULÁRIO DE ACESSO */
const accessForm = {
  confirm_title: "Confirm you’re not a robot",
  phone_code_description: "Get a verification code sent to your phone",
  phone_code_info: "We will verify this number via SMS (charges may apply).",
  something_wrong: "Something went wrong",
  validation_finished: "Validation finished",
  redirect_to_app: "You'll be redirected to the application.",
  error_too_many_requests:
    "Please, wait a little bit before making another request.",
  error_verify_code:
    "It was not possible to send the verification code. Try again.",
  error_invalid_verification_code: "Invalid code.",
  error_code_expired: "Code expired.",
  phone: "Phone",
  international_phone_hint: "Insert phone number with DDI, DDD and number",
  recaptcha_title: 'Solve "Captcha"',
  recaptcha_description:
    "After you solve it, you'll receive an SMS with a 6 digits code to insert below",
};

/* PÁGINA INICIAL */
const home = {
  homepage: "Homepage",
  users_description: "View users and assign roles.",
  groups_description: "Create, view and edit shift settings.",
  password_settings_description:
    "Request password changes and set an temporary password.",
  integrations_description:
    "Configure integrations with VR Gente | PontoMais and Conecta Control AD.",
  customize_description:
    "Customize your company's logo on the login page of Conecta Control",
  alerts_description:
    "View notifications about possible errors and login reports.",
  conecta_suite_description:
    "Gain access to new settings to connect management to technology.",
};

/* NAVBAR (BARRA SUPERIOR) */
const navbar = {
  global_search: "Search users, shifts or settings",
  privacy_policy: "Privacy policy",
  terms_of_use: "Terms of use",
  active_dark_theme: "Enable dark theme (BETA)",
  active_light_theme: "Enable light theme",
  quick_settings: "Quick settings",
  support: "Support",
  update: "Update",
  new_service_plan: "Hire a plan or a service",
  go_to_self_checkout: "I want to know the plans",
  sign_out: "Sign out",
  //dislable control dialog
  disable_conecta_control_dialog_title: "Disable Conecta Control",
  disable_conecta_control_warning:
    "This change impacts all users of your company",
  disable_conecta_control_description:
    "When deactivating Conecta Control, user authentication happens again with the Google password.",
  disable_conecta_control_description2:
    "It will be necessary to recover the password for users who no longer have the Google password.",
  solve_problem: "Solve the problem: ",
  challenge_input_hint: "Enter the correct value and click verify",
  challenge_input_hint_validation: "Enter the correct value",
  confirm_deactivate_message:
    "To confirm the deactivation of access control with Conecta Control, solve the problem and then click on verify.",
  frequently_questions: "Frequently questions",
  chat_support: "Chat support",
  receive_support_label: "Talk to our agents via chat",
  need_help_question: "Needing help?",
  need_help_label: `Select the support menu on the top bar and then 'Get support' to speak directly with our team.`,
  send_feedback: "Send suggestion",
  new: "New",
  schedule_meeting: "Schedule meeting",
};

/* SIDEBAR (BARRA LATERAL) */
const sidebar = {
  home: "Home",
  users: "Users",
  company_profile: "Company profile",
  groups: "Shifts",
  password_settings: "Password settings",
  integrations: "Integrations",
  alerts: "Alerts",
  send_feedback: "Send suggestion",
  button_feedback: "Send suggestion",
  customize_login: "Customize login screen",
};

/* PRODUTOS E ITENS QUE ENVOLVAM A CONECTA */
const conecta = {
  conecta_suite: "Conecta Suite",
  conecta_track: "Conecta Track",
  conecta_sign: "Conecta Sign",
  conecta_control: "Conecta Control",
  conecta_control_ad_sync: "Conecta Control AD",
  integracao_ponto_mais: "Conecta Control & VR Gente | PontoMais",
  another_products: "Another Conecta products",
  account_manager: "Manage your account in Conecta Suite",
  visible_conecta_only: "Visible to Conecta employees only",
  conecta_partners_simulation: "Partner Mode - You are managing ",
};

/* USUÁRIOS */
const users = {
  synchronizingUsers: "User synchronization is in progress",
  syncUsers: "Synchronizing users",
  system: "System",
  delete_user: "Deleted user",
  update_user: "Updated the user",
  add_new_user: "Added new user",
  user_activate_sso: " activated Conecta Control",
  user_disable_sso: " disabled Conecta Control",
  user_activate_sso_event: "Activate Control",
  user_disable_sso_event: "Disable Control",
  user_removed_group_rules: "Removed hour rules from shift",
  receive_user_single_access: "Receive temporary access",
  removed_single_access_from: "Removed temporary access",
  user_added_group_rules: "Added hour rules to shift",
  user_deleted_groups: "Deleted the shifts",
  user_updated_group_rules: "Edited shift rules",
  receive_single_access: "Grant temporary access",
  removed_single_access: "Remove temporary access",
  granted_single_access: "released temporary access",
  for: "for",
  to: "to",
  lost_user_single_access: "removed temporary access from",
  grantedSingleAccess: "Granted temporary access",
  active_users: "Active users",
  inactive_users: "Inactive users",
  participant_of: "Participant of",
  user: "User",
  search_users: "Search users",
  show_all_users: "Showing all users",
  users_selected: "selected users",
  last_login: "Last login",
  without_group: "Users without shift",
  is_on_vacation: "Users on vacation",
  without_group_alert: "Some users are not assigned to a shift",
  without_group_alert2: "assign them at least one shift",
  confirm_delete_user_from_group_label:
    "Do you want to confirm the removal of this user from the shift ",
  confirm_delete_users_from_group_label:
    "Do you want to confirm the removal of selected users from the shift ",
  you_are_editing_this_user_group_membership:
    "You are editing this user's shift membership.",
  manage_user_shifts_label: "Manage the shifts this user participates in.",
  vacation_period: "Vacation",
  scheduled_vacation: "Scheduled vacation",
  on_vacation: "Is on vacation",
  vacation_title: "Set vacation mode",
  vacation_subtitle: "Select the vacation period for session lock",
  vacation_description:
    "Vacation mode blocks users from accessing their accounts for a certain period of time.",
  set_user_vacation: "Assign vacation mode",
  edit_vacation_period: "Edit vacation period",
  remove_user_vacation: "Remove vacation mode",
  set_users_vacation: "Assign vacation mode to users",
  deny_user_access_in_vacation: "Block users' access while on vacation",
  deny_user_access_in_vacation_description:
    "Choose which users you want to block for the holiday period and apply the Workspace access block for current or future periods.",
  remove_users_vacation: "Remove users' vacation mode",
  request_single_access_description:
    "Enter your email address and describe to the administrators the reason for the request.",
  request_password_change_description:
    "Enter your email address and comment about your password change request to your administrators.",
  login_history: "Login history",
  history: "history",
  title_deny_browser_access_in_conecta_control:
    "Choose any browser that you want to allow access in a easy and simple way!",
  description_deny_browser_access_in_conecta_control:
    "Select a specific shift of your choice and add the browsers that you want to allow access! Can't be more simple than that!",
  changed_the_password_of: "changed the password of",
  change_own_password: "changed own password",
  apply_vacation_message_title: "Apply a holiday message to your employees.",
  apply_vacation_message_subtitle:
    "Configure an automatic vacation response to notify that the user will not be able to respond to emails received during the recess period determined in the application.",
  apply_vacation_question: "Do you want to apply a holiday message?",
  go_to_sign: "Yes, go to Conecta Sign",
  min_len_is_10: "It must contain at least 10 characters",
  user_on_vacation_in_pontomais: "User is on Vacation in VR Gente | PontoMais",
  all_users_selected: "All users in the table have been selected",
  create_users: "Create users",
  control_only_password: "This password works only to login with Control",
  current_session_state: "Current session state",
  org_units: "Organizational units",
  hasNoValuesToShow: "There are no values ​​to display",
  customizeColumns: "Customize column",
  moveColumnUp: "Move column up",
  moveColumnDown: "Move column down",
  user_added_manager_permission: "Added manager",
  user_removed_manager_permission: "Removed manager",
  has_been_added_admin: "has been added as a manager",
  is_no_longer_an_administrator: "is no longer manager",
};

const groups = {
  search_group: "Search shift",
  show_all_groups: "Showing all shifts",
  show_all_participants: "Showing all participants",
  participants_selected: "selected participants",
  add_group: "Add shift",
  add_participants: "Add participants",
  add_participants_label:
    "Add new participants for this shift by entering their email addresses.",
  add_all_users_label:
    "You can add all users in the organization at once this shift.",
  add_all_users_confirm_action: "Add all current users to ",
  edit_participants: "Edit participants",
  groups_selected: "selected shifts",
  manage_participants: "Manage participants",
  edit_password_policy: "Edit password policy",
  edit_operation_hours: "Set up time settings",
  edit_login_logout_access: "Set up login and logout settings",
  pontomais_login_rules: "VR Gente | PontoMais integration rules",
  login_rules: "Login rules",
  logout_rules: "Logout rules",
  clocking_session_control_title: "Clock time provider settings",
  clock_selector_title: "Select the clock time provider",
  clock_provider_label: "clock time provider",
  pontomais_login_message: "Allow to log in only after clocking in",
  pontomais_auto_logout_info:
    "Logout can take up to 5 minutes to occur after clocking in",
  pontomais_auto_logout_label:
    "Log out users from all devices after clocking...",
  pontomais_end_auto_logout_label: "After clocking out when shift is finished",
  pontomais_break_auto_logout_label: "After clocking out just for a break",
  pontomais_login_description:
    "Set whether users can only log in after clocking in",
  pontomais_auto_logout_message:
    "Log out users from all connected devices after clocking out when their shift is finished or just for a break",
  pontomais_end_auto_logout_description:
    "User will be logged out after clocking out at the end of the working day",
  pontomais_break_auto_logout_description:
    "Will be logged out after registering the exit point for the range",
  pontomais_link_how_to_config_shift:
    "How to configure shifts in VR Gente | PontoMais",
  advanced_logout_rule: "Log out users from all devices after hours",
  logout_rule: "Log out users after shift time",
  next_group_password_change: "Next password change on ",
  there_are_no_settings: "There're no settings",
  always_active: "Always active.",
  always_inactive: "Always inactive",
  scheduled_activation: "Scheduled for activation",
  group_info: "Shift info",
  delete_group_title: "Shift deletion",
  delete_group_label:
    "All shift information including access rules will be deleted. Participant accounts will not be deleted.",
  activate_group_label:
    "Activate shift now to start restricting your users according to the defined rules.",
  disabled_group_label:
    "This action will disable the shift, and your users will no longer be able to access following the shift's rule. Disable only if you are sure that users are already on another shift.",
  delete_group_alert:
    "<b>Attention!</b> Users who are participating in the shift will no longer be subject to its rules. <br> <strong>We suggest re-assigning the users to another shift.</strong>",
  clone_group_label: `<p>This action will clone the shift and its information.</p>
    <p>All settings for that shift will be cloned to a new shift, such as users, accesses and times.</p>`,
  logout_group:
    "Participants in this shift will be logged out, do you want to continue?",
  the_group: "The shift",
  the_groups: "The shifts",
  has_been_deleted: "has been deleted",
  groups_have_been_deleted: "have been deleted",
  cannot_be_deleted: "cannot be deleted",
  groups_cannot_be_deleted: "cannot be deleted",
  delete_selected_shifts: "Delete selected shifts",
  cant_delete_groups: "Shifts must be disabled",
  cant_activate_groups: "Only enabled shifts have been selected",
  cant_deactivate_groups: "Only disabled shifts have been selected",
  was_cloned: "was cloned",
  could_not_be_cloned: "could not be cloned",
  updated_password_at: "Last password change",
  automatic_logout: "Automatic logout",
  next_password: "Next password change",
  days_to_change_password: ["Exchange every", "days"],
  login_shifts: "Login",
  login_label:
    "Validates if the user is in working hours via time control software.",
  session_termination: "Session termination",
  session_termination_label: `Shift participants are logged out at the end of an "access rule" (period) of the shift.`,
  integration: "Check Point",
  password_policy_info_shifts:
    "Defines at which time interval users must change their personal passwords.",
  groups_disabled:
    "The deactivated shifts do not appear in the hours of operation",
  /* Regras */
  rule: "Rule",
  access_rule: "Access time",
  add_rules: "Add rules",
  add_new_rule: "Add new rule",
  remove_rule: "Remove rule",
  edit_rule: "Edit rule",
  update_selected_rules: "Edit selected rules",
  remove_selected_rules: "Remove selected rules",
  showing_all_rules: "Showing all rules",
  selected_rule: "selected rule",
  selected_rules: "selected rules",
  /*  */
  login_logout_access: "Login and logout settings",
  time_settings: "Operation hours",
  auto_logout_label: "Log users out of the browser at the end of the time",
  advanced_logout_label:
    "Log out users at the end of hours from all connected devices",
  add_group_dialog_description:
    "Are you sure you want to add users to this shift?",
  remove_group_dialog_description:
    "Are you sure you want to remove users from this shift?",
  /* Ações */
  logout_groups_success: "Shift successfully logged out!",
  logout_group_success:
    "Participants of this shift have been successfully logged out!",
  logout_groups_failure: "Unable to logout these shifts.",
  logout_group_failure:
    "It was not possible to logout the participants of this shift.",
  force_change_pass_groups_success: "Password change request was successful",
  force_change_pass_groups_failure: "Unable to request password change.",
  participants_info: "Manage shift members by adding or excluding users.",
  password_policy_info:
    "Add a password policy for this shift, so users will need to change their password on a recurring basis.",
  disable_group_info: "Deactivate and activate the shift whenever you want.",
  logout_settings_info: "Define how the user can start and end the session.",
  clone: "Clone",
  add_users_to_group: "Users added to shift",
  remove_users_from_group: "Users removed from shift",
  add_groups_to_user: "Shifts added to user",
  remove_groups_from_user: "Shifts removed from user",
  user_added_on_shifts: "was added to shifts",
  user_removed_from_shifts: "was removed from shifts",
  add_vacation_to_user: "Applied vacation to user:",
  remove_vacation_from_user: "Removed vacation from user:",
  group_config: "Shift Configuration",
  general_config: "General Settings",
  participants: "Participants",
  passwords: "Passwords",
  general_config_description:
    "Manage name, shift status, activation period, etc",
  participants_description:
    "Configure the users who should be affected by this shift's rules",
  passwords_description:
    "Manage periodic password change rules or force immediate password change",
  login_logout_access_description:
    "Define processes that will affect user login/logout",
  time_settings_description:
    "Configure the times and days available for the user to log in",
  clocking_login_config: "Login settings via clocking",
  logout_config: "Logout settings",
  clocking_logout_config: "Logout settings via clocking",

  //mudar isso quando ip e datas especiais forem separados
  ip_address_info:
    "Restriction by IP allows you to allow access by location through IP and DNS addresses.",
  time_settings_info:
    "To enter a period of permission click on the calendar, to exclude a period click on “Operating hours“ to go to the settings.",
  request_group_password_change:
    "Are you sure you want to request a password change for the participants of this shift?",
  restriction_label_by_browser: "Browser lock",
  allowed_browsers_info:
    "Browser restriction allows you to grant access to selected browsers only.",
  allowed_os_info:
    "The restriction by operational system allows you to grant access to selected operational systems only.",
  allowed_browsers: "Allowed browsers",
  allowed_os: "Allowed operational systems",
  restrict_operational_system: "Restrict operational system",
  os: "operational system",
  activation_period: "Shift activation period",
  activation_period_description:
    "Choose the time interval the shift will remain active",
  activation_period_placeholder: "Period that the shift will remain active",
  optional: "optional",
  disable_shift_message_in_period: `<strong>WARNING:</strong> this shift has a defined activation period. If confirmed, this setting will be removed.`,
  edit_activation_period: "Edit activation period",
  remove_activation_period: "Remove activation period",
  activate_shift_message_in_period: `This shift has a defined activation period, upon confirmation, this setting will be removed.`,
  shift_message_in_period_in_bulk: `One or more shifts have the activation period defined, upon confirmation, this setting will be removed.`,
  activate_shift: "Activate shift",
  activation_period_label: "Select shift activation period",
  activate_shifts_successfully:
    "The selected shifts have been activated successfully",
  deactivate_shifts_successfully:
    "The selected shifts have been successfully deactivated",
  deactivate_shifts_error:
    "There was an error disabling shifts, please try again",
  activate_shifts_error:
    "There was an error activating shifts, please try again",
  deactivate_selected_shifts: "Deactivate selected shifts",
  cancel_schedule_activation: "Cancel schedule",
};

const adminPermissions = {
  user_permissions: "User permissions",
  manage_permissions: "Manage permissions",
  add_admin: "Add admin",
  remove_admin: "Remove admin",
  add_admin_main_text:
    "Select a user to add administrator permission in Conecta Control.",
  add_admin_helper_text:
    "This setting does not interfere with Google Admin permissions.",
  remove_admin_main_text:
    "To remove the Conecta Control administrator permission from this user, click the confirm button.",
  admin_permissions_description:
    "Manage the administrators of the Conecta Control panel",
};

/* TOUR */
const tour = {
  buttonSkip: "Skip",
  startTutorial: "Start tutorial by tool",
  buttonPrevious: "Previous",
  buttonNext: "Next",
  buttonStop: "Finish",
  getting_start: "Getting start",
  user_tour: "Manage users",
  user_tour_description:
    "View workspace users, their routines and change passwords.",
  company_profile_tour: "Update profile",
  company_profile_tour_description:
    "Visit the connect suite and update your company profile.",
  permissions_tour: "User permissions",
  permissions_tour_description:
    "Manage and assign permissions for other users to manage the Conecta Control panel. Permissions are unique to this panel.",
  group_tour: "Set up a shift",
  group_tour_description:
    "With shifts, you can create custom access rules by date, time and location.",
  password_tour: "Set an temporary password",
  password_tour_decription:
    "Set an temporary password for users first login or for user password reset.",
  integrations_tour: "Integrations settings",
  integrations_tour_description:
    "Combine Conecta Control with up to two powerful tools and boost access control.",
  suite_tour: "Conecta Suite",
  suite_tour_description:
    "Access the Conecta Suite dashboard, simplify Google Workspace management with advanced settings for users, bulk application of email signatures and vacation messages.",
  config_tour: "Enable access control for everyone",
  config_tour_description:
    "Configure, customize and enable or disable Conecta Control as a login manager for your users. Don't worry! After its configuration, users will remain logged in until the logout time established by the shift to which they belong.",
  activate_control_tour: "Activation of Conecta Control",
  activate_control_tour_description:
    "After the settings, activate Conecta Control. Users will remain logged in until the logout time set by the shift they belong to.",
  start_settings: "Start configuration",
  start_settings_after: "Do not configure for now",
  firstStepTitle: "Google Workspace access control",
  firstStepDescription:
    "Welcome, we are happy to have you as our newest user! To start using all the benefits of Conecta Control, you need to take a few steps to finally activate your product",
  secondStepTitle: "Defining shifts",
  secondStepDescription:
    "Decide <b>when and where</b> your users can access Google Workspace by creating a shift with custom rules.",
  thirdStepTitle: "Add users to shifts",
  thirdStepDescription:
    "Include users as members of a shift so that they follow pre-established rules",
  fourthStepTitle: "Activate Conecta Control",
  fourthStepDescription:
    "At the end of the process, all you have to do is activate the application so that the applied rules start to apply to users. Let's start the setup?",
  schedule_activation_logout_alert:
    "Users will not be automatically logged out after automatic activation",
};

const welcome = {
  title: "Welcome!",
  title_login_preview: "Welcome",
  subtitle:
    "In a few moments your users will be synchronized.<br>Check out what you need to know to get started in Conecta Control:",
  step1title: "Access control to Google Workspace",
  step1description:
    "Conecta Control is like a security layer for accessing Google Workspace products.",
  step2title: "Access restrictions with shifts",
  step2description:
    "Create shifts and define who, when and where to have access to Google Workspace tools.",
  step3title: "Don't worry!",
  step3description:
    "The Conecta Control access control will only take effect after its configuration, we will guide you through this process.",
};

const activationSteps = {
  getStartedTitle: "Welcome to activating Conecta Control",

  beforeStartActivationProccess: "Before starting, you need to know",

  getStartedSteps: "This is a 3 step process",

  getStartedActivationProccess: "You can pause this process at any time",

  getStartedProgress: "Your progress will not be lost if you leave this page",

  getStartedLogoutUsersTip:
    "At the end of the process, you will decide whether users will remain logged in or will have to log in again",

  letsStart: "Let's start",

  skipStep: "Skip step",

  whatDoYouWantToDo: "What do you want to do now?",

  redirectTitle: "You will be redirected to",

  dontWorryYourActivationProgressIsSaved:
    "Don't worry, your progress so far will not be lost",

  comeBackAnytimeByClickingInControlActivation: `Come back at any time by clicking on "Activate Connect Control"`,

  activationStepOneTitle: "User access",
  activationStepTwoTitle: "Temporary password",
  activationStepThreeTitle: "Configure and activate",

  activationSessionOneTitle: "Set user access",

  userShiftSettings: "Configure user shifts",

  shiftTip:
    "With shifts, <b>define access rules</b> that your <b>participants must follow to access</b> the Workspace",

  shiftMembersTip:
    "<b>Add users to shifts</b> so they can <b>follow</b> those <b>rules</b>",

  nonShiftMembersTip:
    "<b>Users without a defined shift</b> will <b>not be able to log in</b> after activation",

  shiftChanges:
    "<b>Adjust</b> shifts, participating users, access rules and logout <b>whenever you want</b>, <b>without</b> having to <b>reactivate</b>.",

  userOptionRedirectToShifts: "I want to create/edit my own shifts",

  userOptionRedirectToUsers: "I want to add users to existing shifts",

  activationSessionTwoTitle: "Set temporary password",

  whatDoYouWantToDoWithUsersFirstAccess:
    "How would you like to set up your users' password?",

  defineTemporaryPassword: "Set temporary password",

  temporaryPasswordWarningCoWorkers:
    "Warn users about <b>first access with temporary password</b>.",

  temporaryPasswordUserChangePassword:
    "Users new to Control will <b>be able to choose a new password</b> on their first login.",

  temporaryPasswordFutureChanges: `The temporary <b>password</b> <b>can be changed or consulted</b> at any time in the <b>"Password Settings"</b> menu.`,

  temporaryPasswordChanged: "Temporary password configured",

  sendEmailToChangePassword: "Send password change email",

  tipToAdminAboutThePasswords:
    "The <b>auto-generate passwords are being sended through e-mail at the moment.</b> The users can changed it after logging in.",

  tipToAdminAboutChangePasswordLink:
    "The <b>links are being sent by email at the moment.</b> Users will be able to change their password through the link.",

  informUsersAboutTheNewPassword: "Inform employees of the following password:",

  informUsersAboutRandomPassword:
    "The automatically generated temporary password will be sent individually to each user's main email.",

  informUsersAboutEmailToChangePassword:
    "Emails will be sent individually to each user's primary email.",

  informAdminAboutTheRandomPasswords:
    "Don't you worry, <b>the auto-generate passwords are going to be sended via e-mail</b> after <b>concluding this step</b>.",

  informAdminAboutChangedPassword:
    "Upon clicking the link, <b>users will need to log in with Google</b> to access the <b>password change</b>.",

  informAdminAboutActivationWithEmail:
    "<b>It is advisable to schedule the activation or wait</b> until all users have changed their passwords.",

  whatDoYouWantToDoWithLoggedUsers:
    "What do you want to do with the users who are currently logged in?",

  activationUsersLogoutHelp: `If you prefer, <b>you can log out users at any time</b> via the "Users" page or log out per turn on the "Shifts" page`,

  redirectToForceLogoutTip:
    "After activation, select the users who should be logged out",

  forceLogoutToAllUsersTip: "All currently logged in users will be logged out",

  dontForceLogoutTip:
    "Users who are currently logged in will not have their sessions terminated",

  setUsersLogout: "Set users logout",

  activatingControlAccess: "Activating access control..",

  activatingControlAccessTip:
    "While configuring Control, notify collaborators about first access with the following password:",

  control_activation: "Conecta Control Activation",
  stepSectionTitle: "Let's configure or Connect Control",
  stepSectionSubtitle: "In more stages you will activate or access control",
  activationStep1Description: `<p>Firstly, it is necessary to configure and organize the shifts with the users and the times in which they can access their Google account, if you still do not have this, click on "Configure"</p>
  <p>Learning that this stage is essential for the operation of the tool!</p>
  <p>To access Google Workspace products, the user needs to be part of a shift. Proceed as soon as you want to completely restrict or access these users.</p>
  <p>Now we are ready to give sequence to the activation of Conecta Control.</p>
  <p>Or next step is to <b>configure the default password</b> da tool, come on?</p>`,
  activationStep2Description: `<p>You're here, how great, now we're going to configure the default display, I promise it will be quick.</p>
  <p>The default sign is of <b>great importance in activating and not using Conecta Control</b>, but why?</p>
  <p>This will be the first sense that your collaborator will access or Conecta Control, after the first access or collaborator will create a new access path.</p>
  <p>Now that you have created the default or next step and let your collaborators know about this change, shall we?</p>`,
  activationStep3Title: "Warning the collaborators",
  activationStep3Description: `<p>But this part is also very important! We are almost ready to finalize the activation process of Conecta Control<p>
  <p>You only need to notify your collaborators about this move.</p>
  <p>As you can see, we are leaving a prompt message for this notice, you only need to copy and send by e-mail.</p>
  <p>Not easy, then send a message, wait 1 to 3 days so that everyone has heard the message, we also recommend that you send this notice via WhatsApp :)</p>
`,
  warning_control_activation_message_first: `<p>Precious collaborator,</p>
  <p>After or day <span style="color:red">[insert here or day]</span> If requested or a user password Google Workspace (G Suite), you will access with the following temporary password: </p>`,
  warning_control_activation_message_second: `<p><b>Warning:</b> Write down somewhere so you don't forget, after or on the day <span style="color:red">[activation day]</span> you will not be able to access or e-mail with your current password!
  <p>After the first access to the temporary password, you will have to create a new personalized password. It could be the same thing that you are used to.</p>
  <p>Logo depois, you will be able to use Google Workspace (G Suite) tools normally.</p>
  <p>Any doubt, I am available!</p>
  <p>Hugs,<p>
  <p><span style="color:red">[your name]</span></p>`,
  uncompleted_steps: `<p>Some stages seem not to have been concluded and may impact the activation of Conecta Control. Do you want to continue same assim?</p>
  <p>To conclude a stage, click on the number that represents it.</p>`,
  go_to_users: "Go to users",
  go_to_groups: "Go to Shifts",
  notify_users_via_email: "Notify users via email",
  notify_users_via_email_description:
    "All company users will receive an email with instructions on first access using the temporary password provided. Do you wish to continue?",
  generateRandomPasswords: "Generate temporary passwords",
  generate_link_to_change_passwords: "Send a password change link",
  tipAboutExclusivePassword:
    "An exclusive password will be created and sent to each user's main email.",
  tip_about_send_link_to_chenge_password:
    "An exclusive Conecta Control password change link will be sent to each user via email.",
  schedule_activation_description:
    "Program the automatic activation of Conecta Control by choosing the desired data and time",
  activation_date_hint: "Choose a date between today and the next 14 days",
  activation_date_title: "Schedule Conecta Control activation",
  schedule_activation: "Schedule activation",
  activate_later: "Activate later",
  activate_later_description: "Exit and manually activate later",
  activation_domain: "Domain for activation",
  activation_domain_description:
    "Select the domain where you want to activate Conecta Control",
  selectionDomainInfo:
    "<strong>All</strong> <strong>users and administrators</strong> of the selected domain will go through the Conecta Control rules",
};

const activationStepsMs = {
  ms_ways_to_activate_control:
    'It is possible to activate Control for Microsoft by two ways: via "Powershell" or via "Graph Explorer".',
  ms_with_powershell:
    'The activation via a "Powershell" terminal is done by running the following commands:',
  ms_install_module: 'Module installation "Microsoft.Graph"',
  ms_module_authorize: "Conect to autorizate",
  ms_control_activate: "Control activation",
  ms_select_domain:
    "The activation is done in only one domain of your company. Select the domain to update the code.",
  ms_domain_already_federated:
    'In case one domain is already as "Federated", it is necessary to remove federation. Select the domain and use the command below:',
  ms_command_to_deactivate:
    "That's the same command to deactivate Control for Microsoft.",
  ms_with_graph_explorer:
    'The activation in "Graph Explorer" is done with a request with the data provided below and using the tool of the link ',
  ms_rest_api_activate_headers_key: "Add as a request header the key ",
  ms_rest_api_activate_headers_value: " and the value ",
  ms_rest_api_scopes_required:
    "It is required to give permission to the following scopes:",
  ms_rest_api_activate:
    'Select the request method "POST" and insert the following request address (API):',
  ms_rest_api_activate_body:
    "Copy and paste the request body and run the request",
  ms_rest_api_deactivate:
    'In order to deactivate, change the request method to "PATCH" and the request address (API) to the following ',
  ms_rest_api_deactivate_body: "Change also the request body to ",
  domain: "Domain",
  domains: "Domains",
  select_a_domain: "Select a domain",
  select_one_or_more_domains: "Select one or more domains",
  usersNotFoundOnSelectedDomain: "No users found in selected domain",
};

/* TABLE HEADERS EM GERAL */
const headers = {
  name: "Name",
  email: "Email",
  date: "Registration date",
  hour: "Hour",
  ip: "IP address",
  last_login: "Last login",
  permissions: "Permissions",
  admin: "Admin",
  member: "Member",
  group: "Shift",
  username: "Username",
  status: "Status",
  active: "Active",
  not_syncronized: "Not syncronized",
  actives: "Actives",
  disabled: "Inactive",
  author: "Author",
};

const customizeLogin = {
  uplaod_img_label: "Upload your company logo",
  pers_login_title: "Customize your company login page",
  pers_login_subtitle: "Change your login page settings and view in real time",
  change_existing_infos: "Change existing infos",
  primary_color: "Primary button color",
  secondary_color: "Secondary button color",
  text_color: "Text color",
  remove_company_logo: "Remove company logo",
  remove_background_image: "Remove background image",
  remove_logo_error: "Error removing company logo",
  remove_image_error: "Error removing background image",
  crop: "Crop",
  remove_image: "Delete image",
  undo_logo_changes: "Undo changes",
  remove_current_logo: "Delete current logo",
  remove_current_background_image: "Delete current image",
  crop_image: "Crop image",
  logo_width: "Logo width",
  logo_height: "Logo height",
  cut_out: "Cut out",
  logo_size: "Logo size",
  logo_height_placeholder: "Enter height in pixels",
  logo_width_placeholder: "Enter the width in pixels",
  reset_image_dimensions: "Reset image dimensions",
  image_size_error: "Image size must be less than 2 MB!",
  full_screen: "Full screen",
  view_in_full_screen: "View in full screen",
  background_image: "Background Image",
  background_image_description: "Use a custom image on your login screen.",
  recommendations: "Recommendations:",
  image_color_recomendation: "Use a light image (preferably white).",
  image_resolution_recomendation:
    "The image resolution must be high (full hd) to avoid distortions.",
  more_info: "More info",
  powered_by_suite: "This page is powered by Conecta Suite.,",
  logo: "Logo",
};

/* LABELS */
const labels = {
  greater_than_rule: "The value cannot be greater than ",
  less_than_rule: "The value cannot be less than ",
  access_time: "Access time",
  time_unit: "Time unit",
  time_unit_checkbox_text: "Set access time after login",
  time_unit_checkbox_help_text:
    "Check this option to set the time the user will remain logged in after logging in",
  automatic: "Automatic",
  company_logo: "Company logo",
  screen_colors: "Screen colors",
  logo_description:
    "Upload an image and showcase your brand identity to users.",
  screen_color_description: "Customize the login screen colors.",
  height: "Height",
  width: "Width",
  upload_image_title: "Add an image in formats .jpg, .jpge, .png, etc",
  logo_image: "Logo image",
  upload_image: "Upload image",
  description_height_error: "Please enter a height between 40 and 500.",
  description_width_error: "Please enter a width between 40 and 500.",
  restore_default: "Restore default",
  password: "Password",
  type_email: "Type your e-mail",
  type_password: "Type your password",
  access: "Access",
  leave_without_save_changes:
    "Some changes were not saved. Do you want to exit without saving?",
  are_you_sure: "Are you sure you want to ",
  confirm_this_action: "confirm this action",
  sync: "Sync",
  advanced: "Advanced",
  note: "Note: ",
  type_paste_or_search: "Type, paste or search",
  next_logout: "Next logout set to",
  to_cancel_this_edit: 'To cancel editing, click "Cancel".',
  selected_filters: "Selected filters: ",
  choose: "Choose",
  company_profile_label: "Update company information and billing",
  view_more: "View more",
  password_strength: "Password strength",
  password_min_char:
    "The password does not have the minimum number of characters.",
  show_users_without_group: "Show users without shift",
  is_google_admin_label:
    "Indicates whether or not the user has admin permission on the Google Workspace dashboard. This configuration can only be done directly from the Google dashboard.",
  is_active_user_label:
    "Indicates that this user's account has been suspended by the admin in the Google Workspace dashboard.",
  not_syncronized_ms_label:
    "Indicates that this user was not created in Microsoft yet.",
  suspended: "Suspended",
  function: "Role",
  all_of: "All ",
  page_selected: " on this page are selected. ",
  table_selected: " on this table are selected.",
  table_selected_snackbar: " have been selected.",
  select_all_of: "Select all ",
  clear_selection: "Clear selection",
  add: "Add",
  continue: "Continue",
  disabled_log: "DISABLED",
  active_log: "ACTIVATED",
  copy: "Copy",
  define: "Define",
  define_as: "Define as",
  define_password_policy_group: "Set password expiration policy for this shift",
  details: "Details",
  to_view: "View",
  preview: "Preview",
  match: "Matches with",
  never: "Never",
  set_quantity: "Set quantity",
  or: "or",
  basic_info: "Basic Information",
  access_permissions: "Access Permissions",
  password_policy: "Password Policy",
  has_to_change_password_by_period:
    "Participants in this shift must change their passwords every ",
  day_of_week: "Week day",
  month: "month",
  monthly: "Monthly",
  days: "days",
  weeks: "weeks",
  years: "years",
  hours: "hours",
  minutes: "minutes",
  day: "day",
  week: "week",
  year: "year",
  minute: "minute",
  each: "each",
  today: "Today",
  incorrect_dates: "One or more dates entered is incorrect",
  choose_days: "Choose which the days",
  choose_day: "Choose which the day",
  days_upper: "Days",
  only_today: "Today only",
  holiday: "Holiday",
  special_date: "Special Date",
  everyday: "Everyday",
  this_week: "This week",
  this_month: "This month",
  this_day: "This day",
  everyday_in_this_week: "Every day this week",
  weekly: "Weekly",
  start_hour: "Start hour",
  end_hour: "End hour",
  start_date: "Start date",
  end_date: "End date",
  loading: "Loading...",
  no_data: "There isn't data to display",
  no_results: "No results",
  new_password: "New password",
  confirm_new_password: "Confirm your new password",
  confirm: "Confirm",
  request_password_change_label: "Change user password on first login",
  no: "Do not ",
  required_field: "Required field",
  indicates_required_field: "indicates a required field",
  invalid_email: "Invalid e-mail",
  invalid_phone_number: "Invalid phone number",
  max_20_chars: "Maximum of 20 characters",
  code_6_digits: "Code must have 6 digits.",
  passwords_do_not_match: "Passwords don't match",
  logout_settings: "Logout settings",
  access_settings: "Access settings",
  rules: "Rules",
  no_rules_for_today: "No log out set for today",
  no_autologout: "No auto logout",
  participants: "Participants",
  participant: "Participant",
  allowed_ip_addresses: "Allowed IP addresses",
  holidays_and_special_dates: "Holidays and special dates",
  special_dates_step_1: "Select holidays",
  special_dates_step_2: "Insert holidays manually",
  special_dates_step_1_description:
    "Use the calendar or the table in order to select special dates. The button below selects holidays only.",
  special_dates_step_2_description:
    'Selected dates are also presented in the field below. In order to save changes, click "Save".',
  special_dates_table_title: "Holidays and commemorative dates",
  special_dates_get_error: "Could not get holidays.",
  special_dates_select_holidays: "Select national holidays only",
  direct_participants: "Direct participants",
  admins: "Admins",
  business_hours: "Business hour",
  auto_logout: "Auto logout",
  auto_logout_after_closing_window: "Auto logout",
  auto_logout_after_closing_window_description:
    "user closed the management window",
  no_title: "Untitled",
  yes: "Yes",
  all_day: "All day",
  edit: "Edit",
  name: "Name",
  list_ip: "List of allowed IPs",
  invalid_request:
    "The request is invalid and could not be processed by the server, please check your access permissions and try again later.",
  that_is_all_we_know: "That's all we know.",
  this_is_an_error: "This is an error",
  edition: "Edit",
  remove: "Remove",
  combobox_helper:
    "After typing, press <code>ENTER</code> or <code>SPACE</code> to enter a new item.",
  date_format: "Date format: DD/MM/YYYY",
  reset_selection: "Reset selection",
  confirm_password: "Confirm password",
  result: "Result",
  no_date: "No date to display",
  except: "except",
  set_up: "Set up",
  no_group: "No shift",
  you_dont_have_groups: "You don't have shifts",
  notice_to_employees_title: "Notify collaborators",
  notice_to_employees_description: `Inform collaborators about changes to workspace access. Don't worry, click "Learn More" and see a suggested message for you to share`,
  check_as_complete: "Check as complete",
  your_answer: "Your answer",
  call_support: "Call support!",
  vacation_settings_tip:
    "Select the <strong>start</strong> and <strong>end</strong> dates of the vacation period",
  vacation_placeholder: "Select vacation period",
  from: "From",
  until: "until",
  reason: "Reason",
  comment: "Comment",
  user_block_hour: "Outside the allowed hours",
  user_success: "Logged in user",
  user_wrong_password: "Invalid password",
  user_on_vacation: "User on vacation",
  groups_without_rules: "No set time rule",
  user_disabled_domain: "Domain disabled",
  user_block_day: "Outside the allowed hours",
  user_special_block_day: "Special day",
  user_block_ip: "IP not allowed",
  user_without_group: "Unauthorized shift for login",
  user_without_active_group: "Does not belong to any active shift",
  user_not_found: "User not found",
  access_logs: "Access logs",
  last_seven_days: "Last 7 days",
  last_three_months: "Last 3 months",
  last_six_months: "Last 6 months",
  download_csv: "Download CSV",
  ip_address_description:
    "Unique address that identifies a device on the internet",
  browser: "Browser",
  operational_system: "Operational system",
  operational_system_description:
    "The operating system is the program that allows you to communicate with your computer.",
  event: "Event",
  login_attempt: "Login attempt",
  user_block_navigator: "Browser not allowed to access",
  user_block_operational_system: "Operational system not allowed to access",
  user_unknown_ponto_mais: "User not registered at VR Gente | PontoMais",
  user_unknown_senior: "User not found on Senior",
  user_block_ponto_mais: "Check your registration at VR Gente | PontoMais",
  user_block_senior: "Check your registration in the app Marcação de Ponto 2.0",
  unknown_error: "Unknown error",
  user_logout_success: "logged out",
  logout_success: "Forced logout",
  advanced_auto_logout: "Automatic logout",
  single_access_logout: "Automatic logout",
  author_user: "Actor",
  author_description: "The user who performed the action",
  description: "Description",
  force_logout_description: "ended the session",
  closed_office: "Session closed by shift rule",
  end_of_single_access: "End of temporary access",
  pontomais_auto_logout: "Automatic logout",
  senior_auto_logout: "Automatic logout",
  pontomais_auto_logout_description: "Session terminated by point integration",
  more_description:
    "To use this option, you must have at least one user selected",
  watch_the_video: "Watch the video",
  vacation_mode_video_title:
    "Vacation mode for Google Workspace users with Conecta Control",
  dismiss: "Dismiss",
  ip_warning:
    "To know your current IP, <strong><a href='https://www.dnsleaktest.com' target='_black'>click here</a></strong>. If you have questions about how IP addresses work in your company, consult your IT team.",
  start: "Start",
  end: "End",
  duration: "Duration",
  records: "Records",
  hi: "Hi",
  schedule_meeting_description:
    "welcome to Conecta Control! Since is yours first time here, you can schedule a meeting with our team to learn more about the tool. Would you like to schedule a meeting?",
  maybe_later: "Maybe later",
  user_comments: "Leave a comment",
  thanks_for_feedback: "Thank you for your feedback!",
  new_feedback: "New feedback",
  suspended_account: "Suspended account",
  contact: "Contact",
  contact_for_resolution: "Contact us to resolve payment issues",
  expired_code: "Expired code",
  code_expires: "Code expires in",
};

const scopesDialog = {
  scopesDialog_title:
    "To complete your login, please accept all permissions for Conecta Control in the Google Admin Console.",
  scopesDialog_permissions:
    "We need these permissions to provide you a complete experience.",
  scopesDialog_privacy:
    "Your privacy and security are our priority, and you can review the permissions at any time.",
  scopesDialog_faq: `If you have any questions, access our <a href="https://conectasuite.com/perguntas-frequentes/escopos-google-workspace/" target="_blank"><b>FAQ</b></a> or contact us.`,
  scopesDialog_acceptAll: `Access the Google Admin Console and click on "Allow access" to proceed.`,
  scopesDialog_goToAdminConsole: "Go to the Google Admin Console",
  scopesDialog_goToPanel: "Go to the panel",
  scopesDialog_msDidNotRespond: "Microsoft did not respond",
  scopesDialog_checkMsInstallation:
    "Check in your Microsoft 365 Entra ID panel if the application has been installed in your tenant, if the permissions are granted, and if your user has access to the application.",
  scopesDialog_inCaseMsIsInstalled:
    "If everything seems correct, wait a few more minutes for the data to propagate and try logging in again.",
};

const reports_page = {
  reports_loginlogout_title: "Login and logout report",
  reports_loginlogout_description: "Users login and logout logs",
  reports_users_title: "Users report",
  reports_users_description: "Users actions logs",
  reports_groups_title: "Shifts report",
  reports_groups_description: "Shifts actions logs",
  reports_access_time_title: "Access time report",
  reports_access_time_description: "User access time logs per day",
  folders_sharing: "Files and folders",
  folders_sharing_description: "External sharing report",
  shared_files: "Shared files",
  files_shared_externally: "Files shared externally",
  full_size: "Total size",
  sharing: "Sharing",
  users_with_access: "Users with access",
  size: "Size",
  extension: "Extension",
  anyone_with_link: "Anyone with link",
  external: "external",
  internal: "internal",
  access_time_start: "First login.",
  access_time_end: "First logout after the last login.",
  access_time_duration:
    "The period the user was connected to Google, which may differ from the time spent on the machine.",
  access_time_duration_missing_data:
    "Estimated Duration: session continues until the end of the day.",
  access_time_duration_not_finished:
    "Partial duration: session remains active on the current day.",
  access_time_duration_cannot_calculate:
    "No start: missing login record to calculate session duration for the day.",
  upgrade_plan: "Hire plan",
  report_user_permissions_title: "User Permissions Report",
  report_customize_login_title: "Login Screen Customization Report",
  customize_login_label: "Login Customization",
};

/* AÇÕES EM GERAL */
const button_actions = {
  add_filter: "Add filters",
  next: "Next",
  previous: "Previous",
  more: "Apply to selected",
  add_selected_to_group: "Add selected to a shift",
  add_user_to_group: "Add user to a shift",
  add_users_to_shift: "Add users to a shift",
  manage_user_groups: "Manage user shifts",
  remove_selected_from_this_group: "Remove selected from shift",
  remove_user_from_a_group: "Remove this user from a shift",
  remove_user_from_this_group: "Remove user from this shift",
  select_all: "Select all",
  apply: "Apply",
  active_control: "Active Conecta Control",
  inactive_control: "Disable Conecta Control",
  change_password: "Change password",
  reset: "Reset",
  reset_password: "Reset password",
  reset_password_label:
    "The new password must comply with the rules set out in <b>Password Settings > Configure Password Strength</b>.",
  ask_force_logout_reset_password: "Log out from all devices",
  ask_force_logout_reset_password_tooltip:
    "When enabled, this option will disconnect the user account from all connected devices",
  ask_reset_password_first_access: "Change password when logging in",
  ask_reset_password_first_access_tooltip:
    "Enabling this option, the next login will be the password defined here to then be able to reset their own password manually.",
  request_password_change: "Request password change",
  change_user_password: "Change user password",
  force_logout_to_all_users: "Log out all users",
  redirect_to_force_logout: "Select users to forced logout",
  user_password_change: "User password change",
  change_users_password: "Change users password",
  request_logout: "Force logout",
  grant_single_access: "Release temporary access",
  remove_single_access: "Remove temporary access",
  request_users_logout: "Force users logout",
  edit_groups: "Edit this user's shifts",
  change_permissions: "Change permissions",
  changes: "changes",
  close: "Close",
  save: "Save",
  cancel: "Cancel",
  add_selected: "Add selected users",
  more_link_text: "see more",
  verify: "verify",
  disable_control: "Disable Control",
  click_here: "Click here",
  click_to_check_to_conclude: "Click to conclude",
  click_to_uncheck_to_conclude: "Click to remove item from conclude",
  accept_scopes: "Accept scopes",
  see_plan: "SEE PLAN",
  see_integrations: "SEE INTEGRATIONS",
  request_single_access: "Request temporary access",
  user_request_single_access: "User requested temporary access",
  user_email_hint: "Enter the email address you want to access",
  requested_single_access: "requested temporary access",
  requestSingleAccess: "Requested temporary access",
  request_password_change_text: "Request password change",
  user_request_password_change: "User requested a password change",
  requested_password_change_description: "requested a password change",
  to_admins: "to the administrators",
  reports: "Reports",
  reports_description: "Track user activity reports",
  reports_loginlogout: "Login and logout",
  reports_users: "Users",
  reports_groups: "Shifts",
  reports_access_time: "Access time",
  dismiss_text: "Dismiss",
  restore_default_color: "Restore default color",
  activate_selected_shifts: "Activate Selected Shifts",
  use_default_password: "Use default password",
  cancel_activation_sso_async: "canceled automatic activation of Control",
  automatic_activation: "Automatic activation",
  user_activate_sso_async: "activated the control asynchronously",
  continue_without_code: "Continue without code",
  go_to_marketplace: "Go to Marketplace",
};

const password_settings_page = {
  set_default_password: "Set default password",
  password_settings_subtitle:
    "The default password is the password your users will be asked to use when logging in for the first time or when requesting to change their password.",
  defineTemporaryPasswordTip:
    "Your users will have to use this password when logging in for the first time.",
  default_password: "Default password",
  new_initial_password: "New temporary password ",
  password_security: "Configure password security",
  password_settings_subtitle2:
    "Choose which password patterns your users should have",
  min_len_label: "Minimum number of characters:",
  must_have_a_upper_letter: "Must include at least one capital letter.",
  must_have_a_lower_letter: "Must include at least one lowercase letter.",
  must_have_a_number: "Must include at least one number.",
  must_have_a_symbol: "Must include at least one special character.",
  chackbox_label: "Passwords must contain:",
  checkbox_symbols_label: 'Special characters (Ex: “!, @, %, #")',
  checkbox_uppercase_label: "Capital letters (from “A“ to “Z“)",
  checkbox_lowercase_label: "Lowercase letters (from “a“ to “z”)",
  checkbox_numbers_label: "Number (from “0” to “9”)",
  rename: "Rename",
  disable: "Disable",
  delete: "Delete",
  action_cannot_undo: "This action cannot be undone.",
  action_can_undo: "This action can be undone at any time.",
  activate: "Activate",
  create: "Create",
  success_update_default_password_message:
    "Default password updated successfully!",
  error_update_default_password_message:
    "An error occurred while saving the password, try again later.",
  success_update_password_strength: "Password settings saved successfully!",
  error_update_password_strength:
    "There was an error saving password settings, please try again later.",
  current_password: "Current password",
  has_initial_password: "Password configured",
  no_initial_password: "No password configured",
  password_successful_copy: "Password was copied",
  password_unable_to_copy:
    "No se puede copiar la contraseña, inténtalo de nuevo",
  theAdminMustChangeHisOwnPassword:
    "<strong>Administrators using the selected domain will also be affected</strong>",
  adminsMustChangePassword:
    "<strong>Administrators on the selected domain must also change the password</strong>",
};

const integrations_page = {
  your_token: "yourToken",
  logout_webhook_copied_successfully: "Webhook copied successfully",
  current_webhook: "Webhook link",
  clock_status_management: "Status management via WEBHOOK for clock service",
  activate_clock_status_management:
    "Activate this feature and reduce the delay in synchronizing your employees' clock status. When activated, Conecta Control will manage your employees' clock status through WEBHOOK responses.",
  disable_clock_status_management:
    "When disabling this feature, the synchronization time of your employees' clock status will depend exclusively on your clock provider.",
  clock_status_management_warning:
    "Make sure you have configured the WEBHOOK in your clock provider before enabling this feature. Otherwise, DO NOT activate it.",
  autologout_by_pontomais: "Autologout via VR Gente | PontoMais",
  request_integration_activation: "Request integration activation",
  ponto_mais_control: "Clock in control with VR Gente | PontoMais",
  tangerino_control: "Time clock control with Sólides Tangerino",
  clock_time_control_unavailable:
    "Integration with clock time is only available for the Enterprise plan, upgrade to unlock this functionality.",
  pontomais_token_not_found: "VR Gente | PontoMais Token was not found",
  tangerino_token_not_found: "No Sólides Tangerino integration token was found",
  invalid_senior_credentials:
    "Invalid Marcação de Ponto 2.0 application credentials",
  activate_integrations: "ENABLE INTEGRATIONS",
  active_directory_unavailable:
    "Integration with Active Directory is only available for the Enterprise plan, upgrade to unlock this functionality.",
  ponto_mais_integration_subtitle:
    "Configure your company's account VR Gente | PontoMais token to combine the electronic point service and the benefits of Conecta Control",
  tangerino_integration_subtitle:
    "Set up your company's Sólides Tangerino integration token to combine the electronic time clock service with the benefits of Conecta Control.",
  current_token: "Current token",
  active_directory_sync: "Synchronization with Active Directory (AD)",
  active_directory_integration_subtitle:
    "Synchronize Windows Active Directory with Conecta Control AD ​​and simplify the management of users and their passwords",
  copy_paste_pontomais_autologout_function:
    "In order to automatically logout users after clocking out, copy and paste the link below in the VR Gente | PontoMais clocking out webhook configuration",
  download_executable: "Download the executable",
  new_token: "New token",
  save_token: "Save token",
  ponto_mais_token_integration_title:
    "Save VR Gente | PontoMais integration token",
  tangerino_token_integration_title:
    "Set up integration token with Sólides Tangerino",
  update_ad_token_title: "Update Active Directory token",
  update_ad_token_action: "Update AD Token",
  update_ad_token_confirm_phrase:
    "Are you sure you want to update the token? If yes, to maintain the integration you will need to update the token on your server.",
  error_update_ad_token: "Could not update AD token",
  success_update_ad_token: "Token updated successfully!",
  error_update_ponto_mais_token: "Error updating VR Gente | PontoMais token",
  invalid_ponto_mais_token: "The token provided is invalid",
  success_update_ponto_mais_token:
    "VR Gente | PontoMais Token successfully updated",
  success_update_token: "Integration token saved successfully",
  token_copied_successfully: "Token copied successfully!",
  marcacao_ponto_senior_control:
    "Clock in control with Marcação de Ponto 2.0 HCM | Senior",
  senior_credentials_description:
    "Configure the credentials of the application registered on the Senior X Platform to combine the point service and the benefits of Conecta Control",
  config_credentiais: "Configure credentials",
  edit_credentiais: "Edit  credentials",
  access_key: "Access key",
  secret: "Secret",
  tenant_name: "Tenant name",
  success_update_senior_credentials: "Credentials updated successfully",
  clock_time_provider_credentials_not_found:
    "The access credentials for the selected point provider were not found",
};

const sso = {
  disable_sso_warning:
    "Conecta Control is disabled, the settings on the panel will only be applied after activation by an administrator.",
  disable_sso_admin_warning:
    "Conecta Control is deactivated! Go to the settings ",
  and_click_on: " and click on ",
  activate_sso_warning: "There is ",
  activate_sso_warning2: "  with no assigned shift",
  activate_sso_warning3:
    ". Users without a shift will not pass the Conecta Control check.",
  activate_sso_success: "Conecta control activated successfully!",
  activate_sso_error: "Unable to activate Conecta control",
  disable_sso_success: "Conecta control deactivated successfully!",
  disable_sso_error: "Unable to disable Conecta control",
  activate_sso_label:
    "The following action will activate access control for all users in your workspace.",
  action_reversible:
    "This action can be undone at any time by an admin via the settings menu.",
  continue_activation: "Do you want to continue activation?",
  dont_force_logout: "Do not force logout",
  force_users_logout: "Force users to logout to login via Control:",
  schedule_activation_alert_description:
    "Conecta Control activation is scheduled for",
  cancel_schedule_activation_dialog_title: "Cancel activation schedule",
};

const errors = {
  unavailable_group_information: "An error occurred while loading this shift",
  error_update_group: "Unable to update information for this shift",
  error_rules_update: "Unable to save changes to rules for this shift",
  error_logout: "Unable to log out",
  error_create_group: "Unable to create this shift, please try again later",
  error_load_groups:
    "Could not load shifts, check your access permissions with the administrator",

  error_add_admin:
    "Unable to add admin permission for this user, please check your permissions",
  error_remove_admin:
    "Unable to remove admin permission for this user, please check your permissions",
  error_user_page: "An error occurred while loading this user info",
  error_add_user_to_shift: "User cannot be added this shift. Try again.",
  error_remove_user_from_group:
    "User cannot be removed from this shift. Try again.",
  error_remove_users_from_group:
    "Users could not be removed from this shift. Try again.",
  error_participants_to_add_not_found:
    "One or more email addresses entered could not be added",
  error_invalid_emails:
    "One or more email addresses entered are invalid, please correct them and try again.",
  error_invalid_emails_examples:
    "The following email addresses are not valid emails:",
  some_emails_could_not_be_added: "Some emails could not be added",
  error_force_password_change:
    "An error occurred while requesting to change the passwords of selected users",
  error_change_password: "Error changing password, please try again",
  error_vacation_mode: "Error activating vacation mode, please try again",
  error_remove_vacation_mode: "Error removing vacation mode, try again",
  error_grant_single_access: "Error releasing temporary access.",
  error_remove_single_access: "Error removing temporary access.",
  service_unavailable: "Service not available. Please try again later!",
  error_user_logs: "There was an error loading login history. Try again later!",
  error_logout_users_suspended: "Unable to force logout suspended users",
  starthour_later_than_endhour: "Start time cannot be later than end time",
  MISSING_LOGO: "Unable to save your logo",
  MISSING_PRIMARY_COLOR: "Could not save primary color",
  MISSING_SECONDARY_COLOR: "Could not save secondary color",
  not_found: "Not found",
  error_save_senior_credentials:
    "Unable to complete configuration, please check your credentials and try again",
  error_user_already_exists: "User already exists in your user list",
  error_shared_password:
    "An error occurred while sharing passwords via email, please try later.",
  error_send_email_to_change_password:
    "There was an error sending the emails, please wait a moment and try again.",
  scope_error: "Unable to complete the action due to lack of scopes",
  error_reports: "An error occurred while loading the report. Try again",
  error_activation_async:
    "An error occurred while scheduling the activation. Try again",
  error_cancel_activation_async:
    "An error occurred while canceling automatic activation. Try again",
  INVALID_TANGERINO_TOKEN:
    "The integration token provided is invalid! Check the token and try again.",
};

const success = {
  success_config_update: "The settings have been saved.",
  success_group_update: "Shift's information has been updated successfully",
  success_rules_update: "The rules of this shift have been updated",
  success_logout: "User session closed",
  success_users_logout: "Active users' sessions have been terminated",
  success_create_group: "Shift created successfully",
  success_add_admin: "Administrator permission granted to",
  success_remove_admin: "Administrator permission removed from",
  success_remove_user_from_group: "User has been removed from the shift",
  success_remove_users_from_group:
    "Selected users have been removed from the shift",
  success_add_user_to_a_group: "was added to shift",
  success_add_user_to_many_groups: "has been added to multiple shifts",
  success_add_users_to_group: "Users have been successfully added to the shift",
  success_force_password_change:
    "Password change request has been applied to selected users",
  success_change_password: "Password has been updated",
  success_vacation_mode: "Vacation mode has been successfully applied!",
  success_remove_vacation_mode: "Vacation mode has been removed!",
  success_grant_single_access:
    "Temporary access has been successfully released!",
  success_remove_single_access:
    "Temporary access has been successfully removed!",
  success_request_single_access: "Your request was successful!",
  success_request_password_change:
    "Your password change request was sent successfully!",
  saved_logo:
    "Your logo has been saved and is now available on the login page!",
  customize_login_success:
    "Changes have been saved and are now available on the login screen",
  remove_logo_success: "Company logo successfully removed!",
  remove_image_success: "Image successfully removed!",
  save_senior_credentials: "Credentials were saved successfully",
  success_ms_user_created: "User created and syncronized successfully!",
  shared_password_success:
    "A random password will be generated and shared securely via email with your employees. Soon, they will receive the password for access.",
  shared_email_password_success: "Emails have been sent to your collaborators.",
  success_schedule_activation:
    "The activation of Conecta Control has been scheduled successfully!",
  success_cancel_schedule_activation:
    "The automatic activation schedule was canceled successfully",
};

const warnings = {
  warning_control_user_created:
    "Uses created, but it was not possible to syncronize it. Try later to syncronize users, or contact the support.",
};

const plan_status = {
  user_expired_trial: "Trial period expired",
  disabled_or_not_found: `This product is not active for your company. Hire a \
    <a href='${process.env.VUE_APP_SUITE_BASE_URL}/billing/add-plan' target='_blank'>plan to activate it</a> \
    or contact our commercial team to continue using Conecta Control: experience@conectasuite.com`,
  expired_trial: `Your trial period has expired. Hire a \
    <a href='${process.env.VUE_APP_SUITE_BASE_URL}/billing/add-plan' target='_blank'>plan to activate it</a> \
    or contact our commercial team to continue using Conecta Control: experience@conectasuite.com`,
  expiring_trial: `[Your trial expires in <strong>] DD/MM/YYYY. [</strong> Hire a \
    <a href='${process.env.VUE_APP_SUITE_BASE_URL}/billing/add-plan' target='_blank'>plan to activate it</a> \
    or contact our commercial team to continue using \
    Conecta Control: experience@conectasuite.com]`,
  company_not_found:
    "Your company was not found in Conecta Suite. Contact our commercial team to continue using \
    Conecta Control: experience@conectasuite.com",
  product_unavailable: "This product is not active for your company",
  go_to_suite_plans: "Hire a plan to activate it",
  make_plan_upgrade: "Upgrade your plan",
  contract_a_plan: "Contract a plan",
  know_the_plans: "Know the plans",
  contract_control: "Hire Conecta Control now!",
  more_resources_dense:
    "More features: Control time, location, and browser access to Workspace",
  more_resources:
    "Unlock more features, control access by time, location and browser to Workspace with Conecta Control",
  contract_expired_trial:
    "Purchase a plan to continue enjoying all the functionalities of the Conecta Suite products!",
  contract_plan_while_trial:
    "Secure your plan now without losing your remaining free trial days. Starting at R$2,50/user/month!",
  plan_is_expired_trial: "Oops, your trial period is over!",
  enjoying_control: "Are you enjoying Conecta Control?",
  last_trial_day: "Last day of trial",
  expired: "Expired",
  testing_text: "You are testing",
  days_remaining: "Days Remaining",
  remaining_day: "Remaining day",
  company_information: "Company Information",
  request_activation: "Request activation",
  test_ends_in: "Test ends in",
  test_ends_today: "Test ends today",
  test_ended_in: "Test ended in",
  licenses_number: "N° of licenses",
  created_at: "Created at",
  products: "Products",
  activation_error_disabled_plan_title:
    "Oops, unable to activate access control",
  plan_details: "Plan details",
  plan: "Plan",
  plan_payment: "Plan payment",
  /* Plan Periodic */
  flex: "Monthly",
  annual: "Annual",
  trial: "Trial",
  quartely: "Quarterly",
  semester: "Semester",
  biennium: "Biennium (2 years)",
  extra: "Extras",
};

const snackbarActivationErrorMessages = {
  DISABLED_OR_NOT_FOUND:
    "It was not possible to activate access control as Conecta Control is not part of any plan contracted or being tested",
  EXPIRED_TRIAL:
    "Conecta Control could not be activated because the trial period has expired",
};

const reset_password = {
  reset_description:
    "Choose a strong password, preferably one that contains symbols, numbers, uppercase and lowercase letters.",
  REQUIRED_BASIC_AUTH: "Authentication is required to perform this operation",
  INCORRECT_CREDENTIALS: "Incorrect username or password",
  INVALID_PASSWORD: "Password does not meet one or more requirements",
  SAME_PASSWORD: "New password identical to the current one.",
  USER_NOT_FOUND: "The specified user was not found",
  REQUEST_LIMIT_EXCEEDED:
    "You have reached the maximum number of requests allowed for this operation, please try again in a few minutes.",
  min_len: "The password must contain the minimum number",
  min_len_sufix: " of characters.",
  has_upper: "The password should contain at least 1 uppercase character.",
  has_lower: "Password must contain at least one lowercase letter.",
  has_number: "The password must contain at least one number.",
  has_symbol: "Password must contain at least one special character.",
  at_least_an_upper: "At least one capital letter",
  at_least_an_lower: "At least one lowercase letter",
  at_least_an_number: "At least one number ",
  at_least_an_symbol: "At least one special character",
  is_the_same: "La nueva contraseña deve ser diferente de la actual.",
};

const productNames = {
  conecta_suite: "Conecta Suite",
  conecta_control_mobile: "Advanced Logout",
  conecta_control: "Conecta Control",
  conecta_sign: "Conecta Sign",
  conecta_ad_sync: "Active Directory Integration",
  conecta_track: "Conecta Track",
  integracao_ponto_mais: "VR Gente | PontoMais Integration",
  sign_automation: "Sign Automation",
};

const priceChangeWarning = {
  priceChangeWarning:
    "From <strong>July 1, 2024</strong>, prices for Conecta Suite's Standard, Security and Enterprise plans will be adjusted according to the table available on the website",
  noActionNeeded: "No action is required.",
  willingToHelp: "We are available to answer your questions via email",
  thanks4BeeingClient: "Thank you for being a Conecta Suite customer.",
  iUnderstood: "I understood",
  priceChangeSubtitle: "Notice about plan price changes",
  priceAdjustment: "Adjustment in the price of Plans",
};

const companyProfile = {
  companyProfile: "Company Profile",
  companyInfo: "Company Information",
  cnpj: "CNPJ",
  site: "Website",
  corporate: "Corporate Name",
  financeEmail: "Finance Email",
  adminEmail: "Admin Email",
  neighborhood: "Neighborhood",
  address: "Address",
  number: "Number",
  complement: "Complement",
  city: "City",
  state: "State",
  country: "Country",
  zipCode: "ZIP Code",
  companyInfoTitle: "Company Information",
  companyBillingTitle: "Billing",
  companyBillingSubtitle: "Manage your payment details.",
  billingInfo: "Billing Information",
  requiredEmail: "Email is required",
  clearFields: "Clear fields",
  missingCNPJAlert:
    "This company <b>does not have a registered CNPJ</b>. To proceed with the purchase, it is necessary to fill in the required information.",
  notRegistered: "Not registered",
  dataUpdated: "Data updated successfully!",
  fail_to_update_company_in_suite: "Failed to update company information",
  DOWNGRADE_BLOCK:
    "It is not possible to downgrade the plan in the annual mode. Please contact us via email at experience@conectasuite.com.",
  INVALID_SUBSCRIPTION: "Plan periodicity change not allowed",
  fillingRequiredFields: "Fill in the required fields",
  requiredCNPJ: "CNPJ is required",
  insertingCNPJ: "Inserting CNPJ..",
  NOT_IN_CACHE: "Unable to read data for this CNPJ",
  LIMIT_EXCEEDED: "Try again in one minute",
  REJECTED_CNPJ: "CNPJ rejected by federal revenue",
  INVALID_CNPJ: "Invalid CNPJ",
  BAD_REQUEST_CNPJ: "CNPJ not provided",
  INVALID_COMPANY_CNPJ:
    "It was not possible to complete the plan subscription because the contracting company does not have a registered CNPJ in the system.",
};

export const en = Object.assign(
  {},
  feedback,
  login,
  auth,
  accessForm,
  navbar,
  general,
  users,
  conecta,
  reports_page,
  button_actions,
  sidebar,
  headers,
  home,
  labels,
  groups,
  tour,
  password_settings_page,
  integrations_page,
  errors,
  success,
  warnings,
  sso,
  plan_status,
  welcome,
  activationSteps,
  activationStepsMs,
  adminPermissions,
  reset_password,
  customizeLogin,
  productNames,
  snackbarActivationErrorMessages,
  priceChangeWarning,
  scopesDialog,
  companyProfile
);
