const state = () => ({
  activationStep:
    JSON.parse(localStorage.getItem("controlActivationStep")) || 1,

  changedActivationPassword:
    JSON.parse(localStorage.getItem("changedActivationPassword")) || false,

  askedGenerateRandomPassword:
    JSON.parse(localStorage.getItem("askedGenerateRandomPassword")) || false,

  askedSendLinkToChangePassword:
    JSON.parse(localStorage.getItem("askedSendLinkToChangePassword")) || false,

  alreadySharedPasswordViaEmail:
    JSON.parse(localStorage.getItem("alreadySharedPasswordViaEmail")) || false,

  loadingActivation: false,

  temporaryPassword: "",

  domainsToActivate: [],
});

const getters = {
  activationStepsNumber: () => 3,

  changedActivationPassword: (state) => state.changedActivationPassword,

  askedGenerateRandomPassword: (state) => state.askedGenerateRandomPassword,
  askedSendLinkToChangePassword: (state) => state.askedSendLinkToChangePassword,

  currentActivationStep: (state) => state.activationStep,

  temporaryPassword: (state) => state.temporaryPassword,

  sharedRandomPassword: (state) => state.alreadySharedPasswordViaEmail,

  domainsToActivate: (state) => {
    const domainsToActivate =
      state.domainsToActivate.length && state.domainsToActivate;
    if (domainsToActivate) {
      return domainsToActivate;
    }
    const storageDomainsToActivate =
      localStorage.getItem("domainsToActivate") &&
      JSON.parse(localStorage.getItem("domainsToActivate"));
    if (
      storageDomainsToActivate &&
      storageDomainsToActivate.constructor === Array
    ) {
      return storageDomainsToActivate;
    }
    return [];
  },
};

const actions = {
  async sendUsersEmailWithNewPassword({ getters, commit, dispatch }) {
    // const url = "/users/share-temporary-password"; // url sem cloud task
    const url = "/users/share-temporary-password/async";
    await this._vm.$axios
      .post(url, { domains: getters.domainsToActivate })
      .then(() => {
        dispatch("setSnackbarDefault", {
          message: "shared_password_success",
          color: "success",
          show: true,
        });

        commit("setalreadySharedPasswordViaEmail");
      })
      .catch(({ response }) => {
        console.error("vuex: sendUsersEmailWithNewPassword(): ", response);
        dispatch("setSnackbarDefault", {
          message:
            getters.isMicrosoft && response.data.code === "USERS_NOT_FOUND"
              ? "usersNotFoundOnSelectedDomain"
              : "error_shared_password",
          color: "error",
          show: true,
        });
      });
  },
  async sendUsersEmailWithLinkToChangePassword({ getters, dispatch }) {
    // const url = "/users/share-link-change-password"; // url sem cloud task
    const url = "/users/share-link-change-password/async";
    await this._vm.$axios
      .post(url, { domains: getters.domainsToActivate })
      .then(() => {
        dispatch("setSnackbarDefault", {
          message: "shared_email_password_success",
          color: "success",
          show: true,
        });
      })
      .catch(({ response }) => {
        console.error(
          "vuex: sendUsersEmailWithLinkToChangePassword(): ",
          response
        );
        dispatch("setSnackbarDefault", {
          message:
            getters.isMicrosoft && response.data.code === "USERS_NOT_FOUND"
              ? "usersNotFoundOnSelectedDomain"
              : "error_send_email_to_change_password",
          color: "error",
          show: true,
        });
      });
  },
};

const mutations = {
  setActivationStep(state, step) {
    if (step) {
      state.activationStep = step;
      localStorage.setItem("controlActivationStep", JSON.stringify(step));
    } else {
      state.activationStep = 1;
      localStorage.removeItem("controlActivationStep");
    }
  },

  setChangedPasswordState(state, status) {
    state.changedActivationPassword = status;
    localStorage.setItem("changedActivationPassword", status);
  },

  setAskedGenerateRandomPassword(state, status) {
    state.askedGenerateRandomPassword = status;
    localStorage.setItem("askedGenerateRandomPassword", status);
  },
  setalreadySharedPasswordViaEmail(state, status = true) {
    state.askedGenerateRandomPassword = status;
    localStorage.setItem("alreadySharedPasswordViaEmail", status);
  },
  setAskedSendLinkToChangePassword(state, status) {
    state.askedSendLinkToChangePassword = status;
    localStorage.setItem("askedSendLinkToChangePassword", status);
  },

  setLoadingActivation(state, status) {
    state.loadingActivation = status;
  },

  setDomainsToActivate(state, domains) {
    localStorage.setItem("domainsToActivate", JSON.stringify(domains));

    state.domainsToActivate = domains;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
