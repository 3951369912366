export const status = {
  ACTIVE: "Ativo",
  TRIAL: "Trial",
  DISABLED: "Desativado",
};

export const planPeriodicDescription = {
  ANNUAL: "anual",
  FLEX: "mensal",
  QUARTELY: "trimestral",
  SEMESTER: "semestral",
  BIENNIUM: "bienal",
  EXTRA: "licenças extras",
};

export const productNames = {
  conecta_suite: "Conecta Suite",
  conecta_control_mobile: "Advanced Logout",
  conecta_control: "Conecta Control",
  conecta_sign: "Conecta Sign",
  conecta_ad_sync: "Active Directory Integration",
  conecta_track: "Conecta Track",
  integracao_ponto_mais: "Pontomais Integration",
  sign_automation: "Sign Automation",
};
