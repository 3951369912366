import Vue from "vue";
import Vuex from "vuex";
import users from "./modules/users";
import groups from "./modules/groups";
import auth from "./modules/auth";
import sso from "./modules/sso";
import company from "./modules/company";
import activation from "./modules/activation";
import { selfcheckoutLink } from "@/helpers/links";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    snackbar: {
      status: false,
      message_key: "",
      timeout: 5000,
      color: "success",
      is_key: true,
    },
    overlay: false,
    products: ["conecta_suite"],
    language: localStorage.getItem("app_language") || "pt_BR",
    languagesByCode: {
      en: "English (US)",
      pt_BR: "Português (Brasil)",
      es: "Espanhol",
    },
    allLanguages: [
      { title: "Português (Brasil)", lang: "pt_BR" },
      { title: "English (US)", lang: "en" },
      { title: "Espanhol", lang: "es" },
    ],
    previewedFeedbackButton: localStorage.getItem("previewedFeedbackButton"),
  },
  getters: {
    currentLanguage: (state) => state.languagesByCode[state.language],
    currentLanguageCode: (state) => state.language,
    allLanguages: (state) => state.allLanguages,
    products: (state) => state.products,
    loading: (state) => state.overlay,
    snackbar: (state) => state.snackbar,
    showSnackbar: (state) => state.snackbar.status,
    snackbarTimeout: (state) => state.snackbar.timeout,
    snackbarMessageKey: (state) => state.snackbar.message_key,
    snackbarColor: (state) => state.snackbar.color,
    snackbarIsAKey: (state) => state.snackbar.is_key,
    previewedFeedbackButton: (state) => state.previewedFeedbackButton,
    developmentMode: () => process.env.NODE_ENV == "development",
  },
  mutations: {
    setLanguage(state, lang) {
      if (lang) {
        state.language = lang;
        localStorage.setItem("app_language", lang);
      }
    },
    setSnackbarKey(state, value) {
      state.snackbar.is_key = value;
    },
    setSnackbarColor(state, color) {
      state.snackbar.color = color;
    },
    setSnackbarMessage(state, key) {
      state.snackbar.message_key = key;
    },
    showOrHideSnackbar(state, status = true) {
      state.snackbar.status = status;
    },
    setSnackbarTimeout(state, timeout) {
      state.snackbar.timeout = timeout ? timeout : -1;
    },
    setLoadingStatus(state, status) {
      state.overlay = status;
    },
    setPreviewedFeedback(state) {
      state.previewedFeedbackButton = true;

      localStorage.setItem("previewedFeedbackButton", true);
    },
  },
  actions: {
    setSnackbarDefault({ commit }, snackbar) {
      commit("setSnackbarKey", true);
      commit("setSnackbarMessage", snackbar.message);
      commit("setSnackbarColor", snackbar.color ? snackbar.color : "success");
      commit("setSnackbarTimeout", snackbar.timeout ? snackbar.timeout : 5000);
      commit("showOrHideSnackbar", true);
    },
    async setSnackbarCustomMessage({ commit, state, dispatch }, snackbar) {
      commit("setSnackbarKey", false);
      commit("setSnackbarMessage", snackbar.message);
      commit("setSnackbarColor", snackbar.color ? snackbar.color : "success");
      commit("showOrHideSnackbar", true);
      await setTimeout(function () {
        commit("setSnackbarKey", true);
        dispatch("resetSnackbarInfo");
      }, state.snackbar.timeout);
    },
    resetSnackbarInfo({ commit }) {
      commit("showOrHideSnackbar", false);
      commit("setSnackbarKey", true);
      commit("setSnackbarMessage", "");
      commit("setSnackbarColor", "success");
    },
    async setLoading({ state }, time = 5000) {
      state.overlay = true;
      await setTimeout(function () {
        state.overlay = false;
      }, time);
    },
    async setInitialContent({ dispatch }) {
      await dispatch("getUsers");
      await dispatch("getGroups");
      await dispatch("getCompanySsoStatus");
    },

    selfcheckoutRedirect() {
      window.open(selfcheckoutLink, "_blank");
    },
  },
  modules: {
    users,
    groups,
    auth,
    sso,
    company,
    activation,
  },
});
